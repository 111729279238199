<template>
	<div>
		<div class="content">
			<!-- 麵包屑 -->
			<div class="breadcrumb">
				<el-breadcrumb separator="/">
					<el-breadcrumb-item :to="{ path: '/shopping' }">首页</el-breadcrumb-item>
					<el-breadcrumb-item :to="{ path: '/order' }">我的订单</el-breadcrumb-item>
					<el-breadcrumb-item v-if="show">订单详情</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
			<div class="all">
				<div class="center">
					<!-- 导航栏 -->
					<div class="left text-center">
						<el-menu :default-active="activeIndex" class="el-menu-vertical-demo" @select="select" router>
							<div class="words1">交易管理</div>
							<!-- <h3 class="py-15 words">交易管理</h3> -->
							<el-menu-item index="/purchaseOrder"><span slot="title">采购订单</span></el-menu-item>
							<el-menu-item index="/leaseOrders"><span slot="title">租赁订单</span></el-menu-item>
							<el-menu-item index="/mydiagnosisOrder"><span slot="title">诊断订单</span></el-menu-item>
							<el-menu-item index="/mymaintainOrder"><span slot="title">维修订单</span></el-menu-item>
							<!-- <el-menu-item index="5">
                <span slot="title">售后服务</span>
              </el-menu-item> -->
							<h6 class="py-15 words">我的账户</h6>
							<!-- <el-menu-item index="6">
                <span slot="title">收藏商品</span>
              </el-menu-item> -->
							<!-- <el-menu-item index="7"><span slot="title">银行账户</span></el-menu-item> -->
							<el-menu-item index="/address"><span slot="title">地址管理</span></el-menu-item>
							<!-- <el-menu-item index="9">
                <span slot="title">个人信息</span>
              </el-menu-item> -->
							<!-- <el-menu-item index="10">
                <span slot="title">消息通知</span>
              </el-menu-item> -->
						</el-menu>
					</div>
					<div class="right"><router-view></router-view></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			show: false,
			activeIndex: null
		};
	},

	watch: {
		//监听路由
		$route: {
			handler(to, from) {
				// console.log(to, from)
				if (to.name == 'orderDetail') {
					this.show = true;
				} else if (to.name == 'orderDetail-shop') {
					this.show = true;
				} else if (to.name == 'orderDetail-lease') {
					this.show = true;
				} else {
					this.show = false;
				}
			}
		}
	},
	methods: {
		select(index, indexPath) {
			console.log(index, indexPath);
		}
	},
	mounted() {
		//通过判断进入
		this.activeIndex = '/purchaseOrder';
		this.$router.push({
			path: '/purchaseOrder'
		});
	}
};
</script>

<style lang="scss" scoped>
* {
	letter-spacing: 1px;
	.content {
		background-color: #f5f5f5;
		width: 1440px;
		margin: 0 auto;
		.breadcrumb {
			height: 40px;
			color: #8c8c8c;
			letter-spacing: 1px;
			padding-left: 8px;
			background-color: #efeff4;
			.el-breadcrumb {
				display: flex;
				align-items: center;
				height: 40px;
			}
		}
		.all {
			.center {
				width: 1410px;
				display: flex;
				margin: 15px auto;
				padding-bottom: 15px;
				background-color: #f5f5f5;
				.left {
					border-radius: 8px;
					width: 136px;
					margin-right: 10px;
					margin-bottom: 25px;
					.el-menu {
						border-radius: 10px;
					}
					.words {
						background-color: #efeff4;
					}
				}
				.right {
					flex: 1;
					background-color: white;
					border-radius: 10px;
				}
			}
		}
	}
}
.words1 {
	height: 52px;
	line-height: 52px;
	font-size: 16px;
	font-weight: 600;
	background-color: #efeff4;
	color: #262626;
}
</style>
